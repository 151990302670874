import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { useAuth } from "@auth/useAuth"
import { format, isSameDay, parse, parseISO } from "date-fns"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
import BookingDetailsModal from "./_BookingDetailsModal"

const itemsPerPage = 10

const MeetingRoomBookingsPage = () => {
  const { makeRequest, isAuthenticated, user } = useAuth() || {}

  const [reservations, setReservations] = useState([])
  const [filteredReservations, setFilteredReservations] = useState([])
  const [progress, setProgress] = useState(true)
  const [activeItem, setActiveItem] = useState(null)

  const [searchTerm, setSearchTerm] = useState("")

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pages, setPages] = useState(1)
  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(itemsPerPage - 1)

  useEffect(() => {
    setFilteredReservations(reservations)
    // setPages(Math.ceil(reservations.length / itemsPerPage))
    // setTotal(reservations.length)
  }, [reservations])

  useEffect(() => {
    setPages(Math.ceil(filteredReservations.length / itemsPerPage))
    setTotal(filteredReservations.length)
    setPage(1)
  }, [filteredReservations])

  useEffect(() => {
    if (!searchTerm || searchTerm.trim().length === 0) {
      setFilteredReservations(reservations)
    } else if (searchTerm.trim().length >= 3) {
      // search and set
      const temp = reservations.filter(
        ({ space_name = "", user_name = "", id = "", user_email = "" }) => {
          const formattedSearchTerm = searchTerm.trim().toLowerCase()
          return (
            space_name.toLowerCase().indexOf(formattedSearchTerm) > -1 ||
            user_name.toLowerCase().indexOf(formattedSearchTerm) > -1 ||
            user_email.toLowerCase().indexOf(formattedSearchTerm) > -1 ||
            id.toLowerCase().indexOf(formattedSearchTerm) > -1
          )
        }
      )
      setFilteredReservations(temp)
    }
  }, [searchTerm])

  const inc = () => {
    if (page >= pages) return
    setPage(page + 1)
  }

  const dec = () => {
    if (page <= 1) return
    setPage(page - 1)
  }

  useEffect(() => {
    setFrom((page - 1) * itemsPerPage)
    setTo(itemsPerPage * page > total ? total : itemsPerPage * page)
  }, [page, pages, total])

  const fetchBookings = () => {
    makeRequest("list-reservations").then((res) => {
      const { dayPasses, onDemand, meetingRooms, bookings } = res
      setProgress(false)
      const allReservations = [...dayPasses, ...onDemand, ...meetingRooms]
      setReservations(
        allReservations.sort(function (a, b) {
          return new Date(b.date || b.from) - new Date(a.date || a.from)
        })
      )
    })
  }

  useEffect(() => {
    if (!isAuthenticated) return
    fetchBookings()
  }, [isAuthenticated])

  const refresh = async () => {
    await fetchBookings()
  }

  return (
    <>
      <SEO title="Bookings" />
      <Layout title={"Bookings"} progress={progress}>
        {reservations.length === 0 ? (
          <p>No bookings available yet.</p>
        ) : (
          <>
            <div className="mb-3">
              <input
                type="text"
                className="form-control ml-auto"
                placeholder={"Type at least 3 letters to search..."}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ maxWidth: "500px" }}
              />
            </div>
            <hr />
            {filteredReservations.length === 0 ? (
              <p className={"text-center"}>No bookings for the search</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-borderless table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className={"text-nowrap"}>Start & End Date</th>
                      <th className={"text-nowrap"}>Date Reserved</th>
                      <th>Location</th>
                      <th className={"text-center"}>Status</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {filteredReservations.slice(from, to).map((res, idx) => {
                      const {
                        id,
                        user_name,
                        pdf_url,
                        date,
                        from,
                        to,
                        end_date,
                        created_at,
                        space_name,
                        meeting_room_name = null,
                      } = res

                      const dt = date || from
                      const endDt = end_date || to

                      const parsedDate = dt
                        ? parse(dt.split("T")[0], "yyyy-MM-dd", new Date())
                        : null
                      const parsedEndDate = endDt
                        ? parse(endDt.split("T")[0], "yyyy-MM-dd", new Date())
                        : null
                      const validEndDate =
                        endDt && dt && !isSameDay(parsedDate, parsedEndDate)

                      return (
                        <tr key={id} id={`forToolTip${id}`}>
                          <td>
                            <a
                              href={pdf_url}
                              target={"_blank"}
                              rel={"noreferrer"}
                            >
                              {user_name}
                            </a>
                          </td>
                          <td>
                            {format(parsedDate, "MMMM d")}
                            {validEndDate
                              ? ` - ${format(parsedEndDate, "MMMM d, yyyy")}`
                              : format(parsedDate, ", yyyy")}
                          </td>
                          <td>
                            {format(parseISO(created_at), "MMMM d, yyyy")}
                          </td>
                          <td>{space_name || meeting_room_name || "N/A"}</td>
                          <StatusBadge
                            data={res}
                            refresh={refresh}
                            setActiveItem={setActiveItem}
                          />
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
        {filteredReservations.length > 0 && (
          <Row className={"mt-4"}>
            <Col sm={6}>
              <span>
                Displaying {from + 1} - {to} of {filteredReservations.length}
              </span>
            </Col>
            <Col className={"text-right mt-3 mt-sm-0"} sm={6}>
              <button
                className={"btn btn-secondary btn-sm ml-2"}
                onClick={dec}
                disabled={page <= 1}
              >
                Prev
              </button>
              <button
                className={"btn btn-secondary btn-sm ml-2"}
                onClick={inc}
                disabled={page >= pages}
              >
                Next
              </button>
            </Col>
          </Row>
        )}
        <BookingDetailsModal
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </Layout>
    </>
  )
}

export default MeetingRoomBookingsPage

const StatusBadge = ({
  data = {},
  refresh = () => null,
  setActiveItem = () => null,
}) => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [progress, setProgress] = useState(false)

  const {
    status,
    payment_complete,
    id,
    payment_completed,
    reservation_status_id,
  } = data

  const isCompleted =
    !!payment_complete || status === 2 || status === "2" || payment_completed
  const isCancelled =
    status === 3 ||
    status === "3" ||
    status === "cancelled" ||
    reservation_status_id === 3

  const cancelReservation = async () => {
    if (!id || !isAuthenticated) return
    setProgress(true)
    // submit the request to MVP Api
    if (data.ws_mvp_reservation_id) {
      try {
        await axios.post(
          `${process.env.GATSBY_MVP_API_URL}/reservations/cancel`,
          {
            id: data.ws_mvp_reservation_id,
          }
        )
      } catch (e) {
        console.log(e)
      }
    }
    const type = typeof payment_complete === "undefined" ? "day-pass" : "office"
    await makeRequest("cancel-reservation", { id, type })
      .then((res) => {
        refresh()
      })
      .catch((e) => {
        setProgress(false)
      })
  }

  const cancelMrReservation = async () => {
    if (!id || !isAuthenticated) return
    setProgress(true)
    await makeRequest("meeting-rooms/cancel-booking", {
      reservationId: id,
    })
      .then(() => refresh())
      .catch(() => null)
    setProgress(false)
  }

  if (data?.manual_payment) {
    return (
      <>
        <td className={"text-center"}>
          <span className="badge badge-pill badge-secondary">
            Manual Payment
          </span>
        </td>
        <DetailsModal data={data} setActiveItem={setActiveItem} />
        <td />
      </>
    )
  }

  if (isCancelled) {
    return (
      <>
        <td className={"text-center"}>
          <span className="badge badge-pill badge-secondary">Cancelled</span>
        </td>
        <DetailsModal data={data} setActiveItem={setActiveItem} />
        <td />
      </>
    )
  }

  if (isCompleted) {
    return (
      <>
        <td className={"text-center"}>
          <span className="badge badge-pill badge-success">Completed</span>
        </td>
        <DetailsModal data={data} setActiveItem={setActiveItem} />
        <td />
      </>
    )
  }

  return (
    <>
      <td className={"text-center"}>
        <span className="badge badge-pill badge-warning">Payment pending</span>
      </td>
      <DetailsModal data={data} setActiveItem={setActiveItem} />
      <td className={"text-right"}>
        {id && (
          <button
            className={"clear-button-styles ml-2 text-danger px-0 "}
            onClick={data.from ? cancelMrReservation : cancelReservation}
            disabled={progress}
          >
            Cancel
          </button>
        )}
      </td>
    </>
  )
}

const DetailsModal = ({ data, setActiveItem = () => null }) => {
  // if (!data?.meeting_room_id && !data?.type) return <td />
  return (
    <td>
      <button
        className={"btn btn-light btn-sm px-0 py-0"}
        onClick={() => setActiveItem(data)}
      >
        Details{data?.orders?.length > 0 ? " *" : null}
      </button>
    </td>
  )
}
