import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { act } from "react-dom/test-utils"
import OrderDetails from "./_OrderDetails"

const BookingDetailsModal = ({
  activeItem = null,
  setActiveItem = () => null,
}) => {
  return (
    <div>
      <Modal isOpen={!!activeItem} toggle={() => setActiveItem(null)} centered>
        <ModalHeader toggle={() => setActiveItem(null)}>
          Reservation Data -{" "}
          {activeItem?.meeting_room_name || activeItem?.space_name || "N/A"}
        </ModalHeader>
        <ModalBody>
          <OrderDetails
            orders={activeItem?.orders}
            taxPercentage={activeItem?.tax_percentage}
          />
          <table className="table table-bordered m-0 table-striped table-hover">
            <tbody>
              <tr>
                <td>Source</td>
                <td>{activeItem?.source}</td>
              </tr>
              <tr>
                <td>User's Name</td>
                <td>
                  {activeItem?.pdf_url ? (
                    <a
                      href={activeItem?.pdf_url}
                      rel={"noreferrer"}
                      target={"_blank"}
                    >
                      {activeItem?.user_name}
                    </a>
                  ) : (
                    activeItem?.user_name
                  )}
                </td>
              </tr>
              <tr>
                <td>User's Email</td>
                <td>{activeItem?.user_email}</td>
              </tr>
              {activeItem?.price && (
                <tr>
                  <td>Price</td>
                  <td>${Number(activeItem?.price / 100).toFixed(2)}</td>
                </tr>
              )}
              <tr>
                <td>Total</td>
                <td>${Number(activeItem?.total / 100).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Reservation ID</td>
                <td>
                  {activeItem?.reservation_data?.reference ||
                    activeItem?.id ||
                    "N/A"}
                </td>
              </tr>
              <tr>
                <td>Stripe Customer</td>
                <td>
                  {activeItem?.stripe_connected_account_id &&
                  activeItem?.stripe_customer_id ? (
                    <a
                      href={`https://dashboard.stripe.com/${activeItem?.stripe_connected_account_id}/customers/${activeItem?.stripe_customer_id}`}
                      target={"_blank"}
                      className={"text-dark"}
                      rel={"noreferrer"}
                    >
                      {activeItem?.stripe_customer_id || "N/A"}
                    </a>
                  ) : (
                    activeItem?.stripe_customer_id || "N/A"
                  )}
                </td>
              </tr>
              {activeItem?.invoice_url && (
                <tr>
                  <td>Invoice</td>
                  <td>
                    <a
                      href={activeItem?.invoice_url}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      View
                    </a>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setActiveItem(null)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default BookingDetailsModal
