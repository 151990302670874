import React from "react"
import { format, isBefore, isValid } from "date-fns"

const OrderDetails = ({ orders = [], taxPercentage }) => {
  if (!orders || orders?.length === 0) {
    return null
  }
  return (
    <div>
      <h5>Orders</h5>
      {orders.map((order, idx) => (
        <div key={order.id} className={"mt-3"}>
          <h6 className={"d-flex"}>
            Order {idx + 1} <Timestamp createdAt={order?.createdAt} />
          </h6>
          <ul className="list-unstyled">
            {order?.orderItems?.map((item, index) => {
              return (
                <li key={index} className={"d-flex justify-content-between"}>
                  <span>{item.name}</span>
                  <span>${(item.price / 100).toFixed(2)}</span>
                </li>
              )
            })}
            {order.workSpotValue > 0 && (
              <li className={"d-flex justify-content-between"}>
                <span>Work spot</span>
                <span>{formatPrice(order.workSpotValue)}</span>
              </li>
            )}
            <li className="d-flex justify-content-between mt-2">
              <span>Subtotal</span>
              <span>{formatPrice(order.subtotal)}</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>HST ({taxPercentage * 100}%)</span>
              <span>{formatPrice(order.tax)}</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Tip</span>
              <span>{formatPrice(order.tip)}</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Total</span>
              <span>{formatPrice(order.total)}</span>
            </li>
          </ul>
        </div>
      ))}
      <hr />
    </div>
  )
}

export default OrderDetails

const formatPrice = (value) => {
  return `$${(Number(value) / 100).toFixed(2)}`
}

const Timestamp = ({ createdAt = null }) => {
  const date = new Date(createdAt)
  if (!isValid(date) || isBefore(date, new Date("2023-03-28"))) return null
  return (
    <small className={"ml-auto"}>
      Ordered at {format(date, "MMMM d, h:mm a")}
    </small>
  )
}
